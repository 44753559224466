* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.App {
  font-family: "Roboto", sans-serif;
}

p {
  color: #303030;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  padding: 0 2rem;
  border-bottom: 1px solid #e2dfdf;
  background-color: #faeccf;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
}

select {
  padding: 5px;
  // border: 1px solid #da3727;
  border-radius: 10px;
  border: none;
  font-size: 17px;
  font-weight: bold;
  background: linear-gradient(to right, #f7d2a3, #f7d2a3);
  outline: none;
  height: 50px;
}

nav a {
  margin: 0 3px;
  color: #303030;
  text-decoration: none;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: bold;
  // background: linear-gradient(to right,#f0a151,#f7d2a3);
  padding: 10px;
  border-radius: 10px;
  // padding: 7px 13px;
  // border-radius: 3px;
  // transition: all 3s ease-in;
  text-shadow: 0.5px 0.5px #f0a151;
}

nav a:hover {
  background: linear-gradient(to right, #f0a151, #f7d2a3);
  transition: all 0.5s ease-in;
}
.active {
  background: linear-gradient(to right, #f0a151, #f7d2a3);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  color: #303030;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

@media only screen and (max-width: 1200px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    // background-color: #f98d2f;
    // background: linear-gradient(to right, #f0a155, #f6d4a6);
    background-color: #faeccf;
    color: white;
    transition: all 1s;
    transform: translateY(-100vh);
    z-index: 50;
  }

  header .responsive_nav {
    transform: none;
  }
  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // color: white;
  }

  nav a {
    font-size: 1.5rem;
    // color: white;
    // border-bottom: 2px solid white;
    font-weight: bold;
    padding-bottom: 5px;
    text-shadow: 1px 1px #f0a151;
  }
}

//homepage
.home {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #faeccf;
}

.home-page-services-img {
  width: 500px;
  height: 300px;
  // background-color: white;
}

.showcase {
  margin: 0;
  height: 70vh;
  min-width: 100%;
  position: relative;
  border-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #ed7f1c;
  flex-direction: column;
  // z-index: 20;
}

.showcase-title {
  font-size: 2.5rem;
  margin-top: 30px;
  // color: #303030;
  font-weight: bold;
  // text-shadow: 0.3px 0.3px #ffff;
  color: white;
  z-index: 20;
}

.home-page-logo {
  width: 400px;
  height: 300px;
  object-fit: cover;
  border: 0;
  // z-index: 20;
}

.showcase-heading {
  font-size: 36px;
}

.home-after-showcase {
  padding: 20px;
  border-left: 2px solid #f98d2f;
  min-width: 43%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  position: relative;
}

.section-content {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.home-after-showcase p {
  font-size: 20px;
  color: #303030;
  font-weight: bold;
  text-shadow: 0.5px 0.5px #f0a151;
}

.home-description {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.brand-security-loyalty,
.work-online {
  font-size: 20px;
  color: #303030;
  font-weight: bold;
  // width: 60%;
  text-align: center;
  margin: 10px 0;
  text-shadow: 0.5px 0.5px #f0a151;
}

.home-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0;
}

.home-buttons button {
  background-color: #ff6900;
  color: white;
  font-weight: bold;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  margin: 10px 10px;
  font-size: 16px;
  width: 140px;
  height: 40px;
  padding: 5px;
  border: 0;
}

.our-team-heading {
  font-weight: bold;
  font-size: 30px;
  color: #303030;
  margin: 50px 0;
  text-shadow: 1px 1px #f0a151;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.carousel-description {
  width: 100%;
  background: #7f7f7f;
  width: 450px;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  margin: 20px 0;
}

.active-carousel-image {
  width: 300px;
  // height: 250px;
  transition: 0.3s ease;
  object-fit: cover;
  border: 1px solid #f0a151;
  border-radius: 5px;
}

.carousel-description p {
  color: white;
  font-size: 14px;
  font-weight: bold;
  transition: 0.3s ease;
}

.arrow-left {
  background-color: #989898;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  margin: 0px 20px;
  border-radius: 5px;
}

.arrow-right {
  background-color: #989898;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  margin: 0px 20px;
  border-radius: 5px;
}

.dots {
  display: flex;
  margin: 20px 0;
}

.dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #989898;
  margin: 0 5px;
}

.active-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #303030;
  margin: 0 5px;
  transition: all 0.3s ease;
}

//intro page
.intro {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #faeccf;
}
.intro-image-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.intro-image {
  flex: 1;
  text-align: center;
}

.intro-text {
  flex: 1;
}

.intro-img {
  width: 90% !important;
}

.intro-heading h1 {
  font-size: 36px;
  color: #303030;
  margin: 20px 0;
  text-shadow: 1px 1px #f0a151;
}

.intro-p1 {
  max-width: 90%;
  margin: 20px 0;
  font-size: 20px;
  color: #303030;
  text-shadow: 0.5px 0.5px #f0a151;
}

.intro-p2 {
  max-width: 90%;
  margin: 20px 0;
  font-size: 20px;
  color: #303030;
  text-shadow: 0.5px 0.5px #f0a151;
}

//about

.about {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #faeccf;
}

.about-image-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.about-image {
  flex: 1;
  text-align: center;
}

.about-text {
  flex: 1;
}

.about-img {
  width: 600px;
  height: 600px;
  border: 1px solid #f0a151;
  border-radius: 5px;
  object-fit: cover;
}

.about h1 {
  font-size: 36px;
  color: #303030;
  margin: 20px 0;
  text-shadow: 1px 1px #f0a151;
}

.about-p1,
.about-p2,
.about-p3,
.about-p4 {
  max-width: 90%;
  margin: 20px 0;
  font-size: 20px;
  color: #303030;
  text-shadow: 0.5px 0.5px #f0a151;
}

//projects

.projects {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #faeccf;
}

.projects-heading h1 {
  font-size: 36px;
  color: #303030;
  margin: 20px 0;
  text-shadow: 1px 1px #f0a151;
}

.projects-list {
  // max-width: 50%;
  padding: 16px;
}

.projects-list li {
  font-size: 20px;
  color: #303030;
  // max-width: 50%;
  padding: 5px;
  margin: 15px 0;
  text-shadow: 0.5px 0.5px #f0a151;
}

.projects-p1 {
  font-size: 20px;
  color: #303030;
  // max-width: 50%;
  margin: 20px 0;
  text-shadow: 0.5px 0.5px #f0a151;
}

//services
.services {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #faeccf;
}

.services h1 {
  font-size: 36px;
  margin: 20px;
  color: #303030;
  text-shadow: 1px 1px #f0a151;
}

.services h2 {
  margin: 20px 0;
  color: #303030;
  text-shadow: 1px 1px #f0a151;
}

.services-img {
  width: 509px;
  height: 339px;
}

.services-img-text-wrapper {
  display: flex;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
}

.services-img-wrapper {
  flex: 1;
  text-align: center;
}

.services-text-wrapper {
  flex: 1;
}

.services ul li {
  font-size: 20px;
  color: #303030;
  font-weight: bold;
  margin: 20px 0;
  text-shadow: 0.5px 0.5px #f0a151;
  // text-align: center;
  // max-width: 50%;
  list-style: none;
}

.services p {
  max-width: 90%;
  font-size: 20px;
  color: #303030;
  margin: 20px 0;
  text-align: center;
  text-shadow: 0.5px 0.5px #f0a151;
  text-align: left;
}

.services img {
  border: 1px solid #f0a151;
  border-radius: 5px;
  object-fit: cover;
}

//contact page

.contact {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  background-color: #faeccf;
}

.contacts-map-img {
  width: 400px;
  height: 300px;
  object-fit: cover;
}

.btn-location {
  width: 400px !important;
  font-weight: bold !important;
  border-radius: 5px !important;
  font-size: 20px !important;
}

.contact-heading h1 {
  font-size: 36px;
  margin: 20px 0;
  color: #303030;
  text-shadow: 1px 1px #f0a151;
}

.facebook-icon {
  transition: all 0.5s ease;
}

.facebook-icon:hover {
  transform: scale(1.1);
}

.contact input {
  padding: 10px;
  width: 400px;
  // height: 75px;
  // border: 1px solid red;
  background-color: #f8f8f8;
  border: 1px solid #e2e2e2;
  margin-bottom: 10px;
}
.contact h3 {
  text-shadow: 0.5px 0.5px #f0a151;
}

.contact form p {
  margin: 5px 0;
  text-shadow: 0.5px 0.5px #f0a151;
}

.contact p {
  font-size: 20px;
  color: #303030;
  font-weight: bold;
  margin: 15px 0;
  text-shadow: 0.5px 0.5px #f0a151;
}

.required-span {
  color: #da3727;
}

.contact button {
  padding: 12px 14px;
  font-size: 16px;
  color: white;
  background-color: #f98d2f;
  width: 100%;
  outline: none;
  border: none;
  margin: 10px 0;
}

.mobile-number {
  font-size: 30px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.contact textarea {
  width: 400px;
  height: 150px;
  resize: none;
  background-color: #f8f8f8;
  border: 1px solid #e2e2e2;
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  // border: 1px solid red;
}

//not found page

.not-found {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-shadow: 0.5px 0.5px #f0a151;
}

.footer {
  height: 10vh;
  width: 100%;
  // background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #303030;
  font-weight: bold;
  border-top: 1px solid #e2dfdf;
  // background: linear-gradient(to right, #f0a155, #f6d4a6);
  background-color: #faeccf;
  text-shadow: 0.6px 0.6px #f0a151;
}

@media screen and (max-width: 600px) {
  //home page

  .home {
    overflow: hidden;
  }

  .home-page-logo {
    width: 300px;
    height: 300px;
    object-fit: cover;
  }

  .showcase {
    height: 65vh;
  }

  .showcase-heading {
    font-size: 24px;
  }

  .home-after-showcase p {
    font-size: 18px;
  }

  .showcase-title {
    font-size: 1.5rem;
    z-index: 20;
  }

  .brand-security-loyalty,
  .work-online {
    font-size: 16px;
  }

  .our-team-heading {
    font-size: 20px;
  }

  .arrow-left {
    margin: 0 10px;
    width: 40px;
    height: 40px;
  }

  .arrow-right {
    margin: 0 10px;
    width: 40px;
    height: 40px;
  }
  .home-buttons > button {
    padding: 16px;
  }

  .home-page-services-img {
    width: 90% !important;
    // height: 320px !important;
    // display: none;
  }

  .active-carousel-image {
    width: 200px;
    height: 200px;
    // border: 1px solid red;
    // display: none;
  }
  .carousel-description {
    width: 95%;
    padding: 5px;
  }

  .carousel-container {
    padding: 10px;
    // border: 1px solid red;
  }

  //intro pagea
  .intro {
    padding: 10px;
  }
  .intro-heading h1 {
    font-size: 24px;
  }
  .intro-image-text-wrapper {
    flex-direction: column;
  }

  .intro-p1 {
    max-width: none;
    font-size: 15px;
  }
  .intro-p2 {
    max-width: none;
    font-size: 15px;
  }

  //about page

  .about {
    padding: 10px;
  }

  .about-image-text-wrapper {
    // padding: 5px;
    text-align: center;
  }

  .about-img {
    width: 290px;
    height: 250px;
  }

  .about-text {
    text-align: center;
  }

  .about h1 {
    font-size: 24px;
  }
  .about-p1,
  .about-p2,
  .about-p3,
  .about-p4 {
    // max-width: fit-content;
    font-size: 15px;
    text-align: center;
    max-width: none;
  }
  //projects

  .projects-p1 {
    font-size: 15px;
  }

  .projects-heading h1 {
    font-size: 24px;
  }

  .projects-list li {
    font-size: 15px;
  }

  //services page
  .services {
    padding: 18px;
  }

  .services-img {
    width: 290px;
    height: 250px;
  }

  .services-img-text-wrapper {
    text-align: center;
    flex-direction: column;
  }

  .services h1 {
    font-size: 24px;
  }

  .services ul li {
    font-size: 16px;
  }

  .services h2 {
    font-size: 18px;
  }

  .services p {
    // max-width: fit-content;
    font-size: 15px;
    // text-align: left;
    max-width: none;
  }

  //contact pagae

  .contact {
    padding: 10px;
  }

  .contacts-map-img {
    width: 300px;
  }
  .btn-location{
    width: 290px !important;
    font-size: 18px !important;
  }
  .mobile-number {
    font-size: 22px !important;
    font-weight: bold !important;
    text-align: center !important;
  }

  .contact-heading h1 {
    font-size: 24px;
  }

  .contact h3 {
    font-size: 18px;
  }

  .contact input {
    width: 300px;
  }

  .contact textarea {
    width: 300px;
  }

  .contact p {
    font-size: 15px;
    // text-align: left;
  }

  .footer {
    font-size: 14px;
  }
}
